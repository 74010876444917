exports.components = {
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-book-now-js": () => import("./../../../src/pages/book-now.js" /* webpackChunkName: "component---src-pages-book-now-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cottage-at-kaund-js": () => import("./../../../src/pages/cottage-at-kaund.js" /* webpackChunkName: "component---src-pages-cottage-at-kaund-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infrastructure-js": () => import("./../../../src/pages/infrastructure.js" /* webpackChunkName: "component---src-pages-infrastructure-js" */),
  "component---src-pages-kyari-resorts-js": () => import("./../../../src/pages/kyari-resorts.js" /* webpackChunkName: "component---src-pages-kyari-resorts-js" */),
  "component---src-pages-lamyali-farmstay-js": () => import("./../../../src/pages/lamyali-farmstay.js" /* webpackChunkName: "component---src-pages-lamyali-farmstay-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-perisun-js": () => import("./../../../src/pages/perisun.js" /* webpackChunkName: "component---src-pages-perisun-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-retreat-3-day-js": () => import("./../../../src/pages/retreat-3day.js" /* webpackChunkName: "component---src-pages-retreat-3-day-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-summer-camp-js": () => import("./../../../src/pages/summer-camp.js" /* webpackChunkName: "component---src-pages-summer-camp-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

